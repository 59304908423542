import React from "react";
import { Navigate, Route, Routes, } from "react-router-dom";
import { useSelector } from "react-redux";

// strore
import { RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";
import { APICore } from "../helpers/api/apiCore";
import Toaster from "../components/shared/toaster";
 

interface IRoutesProps { }

const AllRoutes = (props: IRoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
    errors: state.Errors.errors
  }));
  const api = new APICore();
  return (
    <React.Fragment>
      <Toaster />
     
      <Routes >
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route >
          {authProtectedFlattenRoutes.map((route, idx) => (

            <Route
              path={route.path}

              element={
                api.isUserAuthenticated() === false ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      // hash:route.path,
                      search: "next=" + route.path,
                    }}
                  />

                ) : (
                  <VerticalLayout {...props}>{route.element}</VerticalLayout>
                )
              }
              key={idx}
            />
          ))}
        </Route>
        <Route path="*" element={<Navigate to="/error-404" />} />
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
