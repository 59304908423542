// apicore
import { NotificationItem } from "../../layouts/Topbar";

// constants
import { NotificationActionTypes } from "./constants";
const mysubmissions = localStorage.getItem("submissions")

const SubmissonNotificationData = mysubmissions === "undefined" ? [] : JSON.parse(localStorage.getItem("submissions") || "[]") as SubmissonNotification[]
// const SubmissonNotificationData =  [] as SubmissonNotification[]
const INIT_STATE = {
    latestNotifications: [],
    counts: 0,
    submissions: SubmissonNotificationData,
    pendingSubmissions: + SubmissonNotificationData.length
};


interface NotificationAction {
    type: NotificationActionTypes.SET_NOTIFICATIONS | NotificationActionTypes.SET_ONLY_NOTIFICATONS | NotificationActionTypes.SET_SUBMISSIONS | NotificationActionTypes.DELETE_NOTIFICATONS
    payload: {
        data?: NotificationItem[];
    };
}
interface SubmissonNotification { id: string, orderId: string, user: { fullname: string } }

interface State {
    latestNotifications: NotificationItem[];
    counts: number;
    submissions: SubmissonNotification[]
    pendingSubmissions: number;
}

const Notifications = (state: State = INIT_STATE, action: NotificationAction): any => {
    switch (action.type) {
        case NotificationActionTypes.SET_NOTIFICATIONS:
            const latestNotifications = [...state.latestNotifications, action.payload.data] as Array<NotificationItem>;
            state.latestNotifications = latestNotifications
            state.counts = Number(latestNotifications.length)
            return { ...state }
        case NotificationActionTypes.SET_SUBMISSIONS:
            const subs = action.payload.data! as unknown as Array<SubmissonNotification>
            state.submissions = subs;
            state.pendingSubmissions = Number(subs?.length)
            return { ...state }
        case NotificationActionTypes.SET_ONLY_NOTIFICATONS:
            state.latestNotifications = [...state.latestNotifications, action.payload.data] as Array<NotificationItem>

            return { ...state }
        case NotificationActionTypes.DELETE_NOTIFICATONS:
            state.submissions = []            
            localStorage.removeItem("submissions")
            return { ...state }
        default:
            return { ...state };
    }
};

export default Notifications;
