import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import { NotificationActionTypes } from "./constants";
import { SagaIterator } from "redux-saga";
import ApiHandlers from "../../helpers/api/handlers";
import { setError } from "../error/actions";

function* FetchNotifications(): SagaIterator {
    try {
        const response = yield call(ApiHandlers.getNotifications);
        if (!response.data.success) {
            yield put(setError(response.data.message));
            return
        }
        yield put(setError([response.data.message]));
    } catch (error: any) {
        yield put(setError([error.message]));
    }
}
export function* watchNotifications() {
    yield takeEvery(NotificationActionTypes.FETCH_NOTIFICATIONS, FetchNotifications);
}
function* notificationSaga() {
    yield all([
        fork(watchNotifications),
    ]);
}
export default notificationSaga;