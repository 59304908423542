import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import Notifications from './notifications/reducers'
import Errors from './error/reducers'
export default combineReducers({
  Auth,
  Layout,
  Notifications,
  Errors
});
