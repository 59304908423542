// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
  | AuthActionTypes.API_RESPONSE_SUCCESS
  | AuthActionTypes.API_RESPONSE_ERROR
  | AuthActionTypes.FORGOT_PASSWORD
  | AuthActionTypes.FORGOT_PASSWORD_CHANGE
  | AuthActionTypes.LOGIN_USER
  | AuthActionTypes.LOGOUT_USER
  | AuthActionTypes.CHECK_CONNECTION
  | AuthActionTypes.LOADING
  | AuthActionTypes.RESET;
  payload: {} | string;
}

interface UserData {
  id: number;
  username: string;
  password: string;
  fullname: string;
  role: string;
  token: string;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (
  username: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { username, password },
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const forgotPassword = (username: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { username },
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
export const checkConnection = (actionType: boolean): AuthActionType => ({
  type: AuthActionTypes.CHECK_CONNECTION,
  payload: { actionType },
});

export const setLoading = (actionType: boolean): AuthActionType => ({
  type: AuthActionTypes.LOADING,
  payload:{actionType},
});