import { ErrorActionType } from "./actions";

const INIT_STATE = {
    errors: []
};

interface ErrorAction {
    type:
    | ErrorActionType.SET_ERROR

    payload: {} | string;
}
interface State {
    errors: string | string[];
}

const Errors = (state: State = INIT_STATE, action: ErrorAction): any => {
    switch (action.type) {
        case ErrorActionType.SET_ERROR:
            return {
                ...state,
                errors: action.payload,
            }
        default:
            return { ...state };
    }
};

export default Errors;
