import jwtDecode from "jwt-decode";
import axios from "axios";

import config from "../../config";
import { cookies } from "./utils";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.APP_ENV === "production" ? config.API_URL : "http://localhost:7140";
axios.defaults.headers.common["api_key"] = config.API_KEY

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      window.location.href = "/access-denied";
    } else {
      switch (error.response.status) {
        case 401:
          message = "Invalid credentials";
          break;
        case 403:
          message = "Access Forbidden";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default: {
          message =
            error.response && error.response.data
              ? error.response.data["message"]
              : error.message || error;
        }
      }
      return Promise.reject(message);
    }
  }
);

const AUTH_SESSION_KEY = "shield_user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "JWT " + token
    axios.defaults.headers.common["sessionId"] = localStorage.getItem("sid")
  }
  else {
    delete axios.defaults.headers.common["Authorization"]
    delete axios.defaults.headers.common["sessionId"]
  };
};

export const getUserFromCookie = () => {
  const user = localStorage.getItem(AUTH_SESSION_KEY)
  if (!user) {
    return null;
  }

  const token = JSON.parse(user).token
  const parsedData = Object.assign(JSON.parse(user), jwtDecode(token))
  return user ? (typeof user == "object" ? user : parsedData) : null;
};
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any | null) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = async (url: string, data: any) => {
    return await axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const headers = {

      "content-type": "multipart/form-data",
    }

    return axios.post(url, formData, {
      headers: headers!
    });
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const headers = {

      "content-type": "multipart/form-data",
    }

    return axios.patch(url, formData, {
      headers: headers!
    });
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    }
    const decoded: any = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      this.setLoggedInUser(null);
      return false;
    } else {
      return true;
    }
  };

  setLoggedInUser = (session: any) => {
    let date = new Date();
    date.setDate(date.getDate() + 7);
    if (session) {
      localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
      localStorage.setItem("sid", session["sessionId"]);
      cookies.set(AUTH_SESSION_KEY, JSON.stringify(session), { expires: date });
      cookies.set("sid", session["sessionId"], { expires: date });
    }
    else {

      localStorage.removeItem(AUTH_SESSION_KEY);
      localStorage.removeItem("sid");
      cookies.remove(AUTH_SESSION_KEY);
      cookies.remove("sid");
    }
  };
  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = getUserFromCookie();
    if (userInfo) {
      const { token, user } = userInfo
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
