import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";
// components
import PrivateRoute from "./PrivateRoute";
// lazy load all the views
// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));

// dashboard
const Dashboard = React.lazy(() => import("../pages/dashboard"));
// Driver
const DriverEditAndView = React.lazy(() => import("../pages/apps/Site/DriverEdit"));
const DriversList = React.lazy(() => import("../pages/apps/driver/DriverList"));

// Users
const CustomerList = React.lazy(() => import("../pages/apps/Users/AllUsers"));
const UserProfile = React.lazy(() => import("../pages/apps/Users/Profile"));

//  Submissions
const AllSubmissions = React.lazy(() => import("../pages/apps/submissions/AllSubmissions"));
const LatestSubmissions = React.lazy(() => import("../pages/apps/submissions/latestSubmissions"));
const SubmissionsView = React.lazy(() => import("../pages/apps/submissions/SubmissionsDetails"));

//  Payouts/Payments
const AllPayouts = React.lazy(() => import("../pages/apps/Site/Payouts"));

//  Error
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Maintenance = React.lazy(() => import("../pages/error/Maintenance"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}


const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboard",
  icon: "airplay",
  header: "Navigation",
  roles: ["Admin"],
  children: [
    {
      path: "/",
      name: "Root",
      roles: ["Admin"],
      element: <Navigate to="/home" />,
      route: PrivateRoute,
    },
    {
      path: "/home",
      name: "Dashboard",
      element: <Dashboard />,
      route: PrivateRoute,
    },

  ],
};


const allAppRoutes = {
  path: "/apps",
  name: "apps",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "shopping-cart",
  children: [
    {
      path: "/apps/customer/profile/:id",
      name: "Users",

      element: <UserProfile />,
      route: PrivateRoute,
    },

    {
      path: "/apps/orders/latest",
      name: "Latest Submissions List",
      roles: ["Admin"],
      element: <LatestSubmissions />,

      route: PrivateRoute,
    },
    {
      path: "/apps/order/view/:id",
      name: "Latest Submissions View",
      element: <SubmissionsView />,
      route: PrivateRoute,
    },

    {
      path: "/apps/customers/list",
      name: "CustomerList",
      element: <CustomerList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/orders/all",
      name: "All Submissions",
      element: <AllSubmissions />,
      route: PrivateRoute,
    },
    {
      path: "/apps/drivers/list",
      name: "Drivers",
      element: <DriversList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/driver/edit/:driverId",
      name: "Drivers",
      element: <DriverEditAndView />,
      route: PrivateRoute,
    },
    {
      path: "/apps/payouts",
      name: "payouts",
      element: <AllPayouts />,
      route: PrivateRoute,
    },
   
  ],
};



const appRoutes = [allAppRoutes]








// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },

  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },


];

// public routes
const otherPublicRoutes = [
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },

  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};
// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,

];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
